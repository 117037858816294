
.Auth {
    margin: 20px auto;
    width: 300px;
    background: #F8F5F4;
    padding: 35px;
    border-radius: 3px;
    text-align: center;
}

.FormButton {
    padding: 20px;
    min-width: 180px;
    background: #11CAA1;
    color: white;
    border: 2px solid #11CAA1;
    box-shadow: none;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    text-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: all .3s;
    
}

.OkButton {
    padding: 7px 15px;
    min-width: 180px;
    background: #11CAA1;
    color: white;
    border: 2px solid #11CAA1;
    box-shadow: none;
    border-radius: 4px;
    font-size: 17px;
    text-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: all .3s;
    margin-top: 20px;
    transition: all .5s;
    outline: none;
}

.OkButton:hover, .OkButton:focus {
    background: #FD7E45;
    border: 2px solid #FD7E45;
    box-shadow: none;
}

@media (min-width: 600px) {
    .Auth {
        width: 500px;
    }
}