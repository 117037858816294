.Main {
  margin-bottom: 100px;
  height: 100%;
  display: flex;
  padding: 0 42px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 72px;
}

.Limit {
  max-width: 1380px;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 800px) {
  .Main {
    width: calc(100% - 48px);
    padding: 0 24px;
    margin-top: 36px;
    margin-bottom: 76px;
  }
} 