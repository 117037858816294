.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    transition: all .5s;
}

.InputElement:focus {
    outline: none;
    transform: translate(1px, -2px);
    box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.3);
    /* background-color: #ccc; */
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}

@keyframes animation3d {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translate(1px, -2px);
        box-shadow: -2px 4px 2px 0px rgba(0,0,0,0.4);
    }
}