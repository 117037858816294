body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Lato', sans-serif;
  background: #44454F top left fixed;
  background-image: url(./assets/images/bgGrey.png);
  background-size: 100px 100px;
  color: #494645;
}

.fieldset {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: 18px 0;
}

.fieldset input {
  width: calc(100% - 36px);
  font-size: 16px;
  margin-top: 10px;
  padding: 10px 18px 11px 18px;
  border: 1px solid #E5DDDC;
  border-radius: 2px;
  color: #666;
  height: calc(56px - 20px);
  -webkit-appearance: none;
  font-weight: 400;
}

.fieldset select {
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px 18px 11px 18px;
  border: 1px solid #E5DDDC;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  color: #666;
  height: 56px;
  -webkit-appearance: none;
  font-weight: 400;
}

.fieldset input, select {
  font-family: 'Lato', sans-serif;
}


.fieldset label {
  font-size: 17px;
  margin: 0;
  text-align: left;
}

.fieldset span {
  font-size: 17px;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 42px;
  width: calc(100% - 84px);
  background-color: #3e3f49;
}

footer .copyright {
  font-size: 18px;
  color: white;
}

footer .p1 {
  margin-bottom: 1em;
  color: #c3c3c3;
  font-size: 12px;
}